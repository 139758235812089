import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-forgot-password',
  templateUrl: './admin-forgot-password.component.html',
  styleUrls: ['./admin-forgot-password.component.scss']
})
export class AdminForgotPasswordComponent implements OnInit {
  UserEmail: any;

  constructor(
    private router: Router,
    private server: ApiService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
   // Retrieve userId from query params
   this.route.queryParams.subscribe(params => {
    console.log(params); // Log the entire params object to see its structure
    this.UserEmail = params['email'];
    console.log(this.UserEmail, 'EMAILLLLL');
  });
  }

  passwordFieldType: string = 'password';
  togglePasswordVisibility() {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }

  passwordFieldType1: string = 'password';
  togglePasswordVisibility1() {
    this.passwordFieldType1 = (this.passwordFieldType1 === 'password') ? 'text' : 'password';
  }

  updatePassword(form: any) {
    let obj = {
      email: this.UserEmail,
      password: form.password,
      confirmPassword: form.confirmPassword,
    }
    console.log(obj, 'objjjjj')
    this.server.adminUpdatePassword(obj).subscribe((res: any) => {
      console.log(res, 'resssssss');
      if (res.statusCode == 200) {

        // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/home'], { queryParams: { userId: this.userId } });
        this.router.navigate(['/admin/signin']);

      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
  }

}
