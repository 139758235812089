<div class="row m-0 footer pt-2">
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
    <h2 class="table mb-0">Come, Visit Us! We are in Bollaram, near Miyapur!</h2>
    <div class="row align-items-center m-0 p-1">
      <div class="col-12 col-sm-3 text-center text-sm-start">
        <img src="../../assets/images/logo.jpg" class="sclImg">
      </div>
      <div class="col-12 col-sm-9 text-center text-sm-end mt-sm-0">
        <a href="https://sclpcb.com" target="_blank" class="aboutLink">
          <h4 class="aboutDataLINK">About SCLPCB</h4>
        </a>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
    <p class="aboutData mb-0">Plot No. 36 & 37, ANRICH Industrial Estate<br><span>IDA Bollaram, Hyderabad – 502325,
        Telangana, India</span></p>
    <p class="aboutData mb-0">information&#64;sclpcb.com</p>
    <p class="aboutData">+919866194011</p>
  </div>
</div>