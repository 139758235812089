<div class="row m-0 p-0 m-3 admin-controls">
  <div class="col-4 m-0 p-0 createAdmin">
  </div>

  <div class="col-4 m-0 p-0 admin-filter" align="right">
    <p class="name"></p>
    <mat-form-field appearance="outline" class="example-form-field1">
      <mat-select ngModel name="selectedAdminStatus" [(ngModel)]="selectedAdminStatus" placeholder="Filter by admin"
        (selectionChange)="onSelectionChangeAdmin($event)">
        <mat-option *ngFor="let admin of adminStatus" [value]="admin.value">
          {{ admin.adminStatus }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-4 m-0 p-0 mt-4 createAdmin" align="right">
    <button class="createAdminBtn" mat-button (click)="openCreateAdminDialog()">Create Admin</button>
  </div>
</div>



<div class="pannel mt-2 mb-4">
  <mat-accordion [multi]="false">
    <div class="pannel">
      <mat-expansion-panel class="mainPanel" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="paneltitle">List of Admins</mat-panel-title>
          <!-- <mat-panel-title class="paneltitle">Create Admin</mat-panel-title> -->

        </mat-expansion-panel-header>
        <div class="mat-elevation-z8">

          <table class="registration_table">
            <thead>
              <tr>
                <th class="gradient-header1" style="width: 7%;">S.No</th>
                <th class="gradient-header" style="width: 27%;">Name</th>
                <th class="gradient-header" style="width: 46%;">Email</th>
                <th class="gradient-header" style="width: 10%;">Status</th>
                <th class="gradient-header" style="width: 10%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="mt-2" *ngFor="let AdminData of AdminStatusData, let i = index">
                <!-- <td class="num">{{ i + 1 }}</td> -->
                <td class="num">{{ i + 1 + (adminCurrentPage * adminPageSize) }}</td>

                <td>{{AdminData.firstName}} {{AdminData.lastName}}</td>
                <td>{{AdminData.email}}</td>
                <td style="color: green;">{{AdminData.status}}</td>
                <td><button class="viewBtn" mat-button (click)="openAdminStatusUpdateDialog(AdminData.id)">
                  <mat-icon>edit</mat-icon>
                </button>
                </td>
              </tr>
            </tbody>
          </table>

          <mat-paginator [length]="totalUsersForAdminStatus" [pageSize]="adminPageSize" [pageSizeOptions]="[5, 10, 20]"
            (page)="onPageChangeAdmin($event)">
          </mat-paginator>

        </div>
      </mat-expansion-panel>
      <!-- </mat-accordion> -->
    </div>
  </mat-accordion>
</div>


<ng-template #createAdminDialog let-data="data" class="bgdone-dialog">
  <div class="create-post-success">
    <div class="publishdialog">
      <div class="d-flex">
        <h5>Create Admin</h5>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="message col-12">
        <form #createAdminForm="ngForm" (ngSubmit)="createAdmin(createAdminForm)">
          <div class="row m-0 p-0">
            <div class="col-6">
              <div style="width: 100%; margin: 10px;">
                <label class="labels">First name<b style="color: red;"> *</b></label>
                <input class="example-form-field" ngModel name="firstName" matInput type="text"
                  placeholder="Enter first name" required>
              </div>
            </div>

            <div class="col-6">
              <div style="width: 100%; margin: 10px;">
                <label class="labels">Last name<b style="color: red;"> *</b></label>
                <input class="example-form-field" ngModel name="lastName" matInput type="text"
                  placeholder="Enter last name" required>
              </div>
            </div>

          </div>

          <div class="row m-0 p-0">
            <div class="col-6">
              <div style="width: 100%; margin: 10px;">
                <label class="labels">Email<b style="color: red;"> *</b></label>
                <input class="example-form-field" ngModel name="email" matInput type="email" placeholder="Enter email"
                  required>
              </div>
            </div>

            <div class="col-6">
              <div style="width: 100%; margin: 10px;">
                <label class="labels">Password<b style="color: red;"> *</b></label>
                <input class="example-form-field" ngModel name="password" matInput type="password"
                  placeholder="Enter password" required>
              </div>
            </div>

          </div>

          <!-- Dialog Buttons -->
          <div class="d-flex mt-5 mb-2 justify-content-center">
            <button mat-dialog-close class="cancelBtn">
              CANCEL
            </button>
            <button mat-button class="proceedBtn" mat-dialog-close [disabled]="!createAdminForm.form.valid"
              type="submit">
              CREATE
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template>


<ng-template #adminStatusUpdateDialog let-data="data" class="bgdone-dialog">
  <div class="create-post-success">
    <div class="publishdialog">
      <div class="d-flex">
        <h5>Admin Status Update</h5>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="message col-12">
        <form #statusUpdateAdminForm="ngForm" (ngSubmit)="statusUpdateAdmin(statusUpdateAdminForm)">
          <div class="row m-0 p-0">
            <!-- <div class="col-10">
                <div style="width: 100%; margin: 10px;">
                  <label class="labels">First name<b style="color: red;"> *</b></label>
                  <input class="example-form-field" ngModel name="name" matInput type="text" placeholder="Select status"
                    required>
                </div>
              </div> -->

            <div class="col-12" align="right">
              <p class="name mt-4"></p>
              <mat-form-field appearance="outline" class="example-form-field-adminStatus">
                <mat-select ngModel name="selectedStatus" [(ngModel)]="selectedStatus" placeholder="Select status"
                  (selectionChange)="onSelectionChange($event)">
                  <mat-option *ngFor="let status of adminStatus" [value]="status.value">
                    {{ status.adminStatus }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Dialog Buttons -->
          <div class="d-flex mt-5 mb-2 justify-content-center">
            <button mat-dialog-close class="cancelBtn">
              CANCEL
            </button>
            <button mat-button class="proceedBtn" mat-dialog-close [disabled]="!statusUpdateAdminForm.form.valid"
              type="submit">
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #OrderValueDialog class="bgdone-dialog">
  <div class="create-post-success">
    <div class="publishdialog ">
      <div class="d-flex">
        <h5>List Of Filtered Admins</h5>
      </div>

    </div>
    <div class="row m-0 p-0">
      <div class="message col-12">
        <h3>No data found.</h3>
        <!-- <h4>Please increase quantity, size, layers...</h4> -->
        <div class="d-flex mt-5 mb-2 justify-content-center">
          <!-- <button matDialogClose class="quickmessage">
                        CANCEL
                    </button> -->
          <button matDialogClose class="myposts">
            OKAY
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>