<div class="signup-cont">
    <div class="center-container">

        <mat-card class="signupcard1 mt-5">
            <div class="container">
                <form #updatePasswordForm="ngForm">
                    <div class="row m-0 p-0" style="justify-content: center;">
                        <div class="passwordTitle mt-2" align="center">
                            <h1 class="password">Set New Password</h1>
                        </div>

                        <div style="width: 70%; margin: 10px;">
                            <input class="example-form-field" [(ngModel)]="this.UserEmail" name="email1" matInput
                                type="text" placeholder="Enter email" required>
                        </div>
                        <!-- 
                        <div style="width: 70%; margin: 10px;">
                            <input class="example-form-field" ngModel name="password" matInput type="text"
                                placeholder="Password" required>
                        </div> -->

                        <div style="width: 70%; margin: 10px; position: relative;">
                            <input class="field" [type]="passwordFieldType" ngModel name="password"
                                placeholder="Enter password" required autocomplete="off" />
                            <img class="eye" src="../../assets/images/icons-eye.png"
                                (click)="togglePasswordVisibility()">
                        </div>

                        <!-- <div style="width: 70%; margin: 10px;">
                            <input class="example-form-field"  ngModel name="confirmPassword" matInput type="text"
                                placeholder="Confirm password" required>
                        </div> -->

                        <div style="width: 70%; margin: 10px; position: relative;">
                            <input class="field" [type]="passwordFieldType1" ngModel name="confirmPassword"
                                placeholder="Re-enter password" required autocomplete="off" />
                            <img class="eye" src="../../assets/images/icons-eye.png"
                                (click)="togglePasswordVisibility1()">
                        </div>

                        <div style="width: 70%; margin: 10px;" align="center">
                            <button [disabled]="updatePasswordForm.invalid" class='reg' mat-button
                                (click)="updatePassword(updatePasswordForm.value)">Reset Password</button>
                        </div>
                    </div>
                </form>
            </div>

        </mat-card>

    </div>
</div>