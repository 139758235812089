<div class="row m-0 footer pt-4">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
        <h2 class="table">Come, Visit Us! We are in Bollaram, near Miyapur!</h2>
        <div class="row">
            <div class="col-3">
                <!-- <img src="../../assets/images/headerFinalLogo-png.png" class="sclImg"> -->
                <img src="../../assets/images/logo.jpg" class="sclImg">

            </div>
            <div class="col-9">
                <a href="https://sclpcb.com" target="_blank">
                    <h4 class="aboutDataLINK">About SCLPCB</h4>
                </a>
            </div>
        </div>
    </div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 ">
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <!-- <h2 class="table">Come, Visit Us! We are in Bollaram, near Miyapur!</h2>
        <img src="../../assets/images/headerFinalLogo-png.png" class="sclImg"> -->
        <p class="aboutData">Plot No. 36 & 37, ANRICH Industrial Estate<br><span>IDA Bollaram, Hyderabad – 502325,
                Telangana, India</span></p>
        <p class="aboutData">information&#64;sclpcb.com</p>
        <p class="aboutData">+919866194011</p>
    </div>
</div>