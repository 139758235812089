<div class="card">
  <div class="row m-0 p-0 mainRow">
    <form #adminProfileUpdate="ngForm" class="w-100">
      <div class="row w-100">
        <!-- Personal Information Section -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <h1 class="headings">Personal Information</h1>

          <div class="input-container">
            <!-- <label for="name">Name</label> -->
            <input [(ngModel)]="name" name="name" class="inputField" placeholder="Enter name" 
                  [disabled]="!isEditing">
          </div>

          <div class="input-container">
            <input [(ngModel)]="mobilenumber" name="mobilenumber" class="inputField"
                   [disabled]="!isEditing" placeholder="Enter mobile number">
          </div>

          <div class="input-containerEmail">
            <input [(ngModel)]="email" name="email" class="inputField"
                   disabled="true" placeholder="Enter email">
          </div>
        </div>

        <!-- Company Information Section -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <h1 class="headings">Company Information</h1>
          <input [(ngModel)]="company_Name" name="company_Name" value="Company Name" class="manualdisplay"
                 [disabled]="!isEditing" placeholder="Enter company name">
          <input [(ngModel)]="adminId" name="adminId" value="User ID" readonly class="manualdisplay"
                  placeholder="Enter user ID">
        </div>

    

        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
          <div class="card image-container">
            <img [src]="url" class="image">
            <div class="camera-container">
              <img src="../../assets/images/addImage.svg" class="camera" (click)="openFilePicker()">
              <input type="file" id="fileInput" (change)="onSelect($event)" style="display: none;" />
            </div>
          </div>
        </div> -->

       

        <!-- Button Section -->
        <div class="col-12 d-flex justify-content-end mt-3">
          <button type="button" mat-button class="profileUpdateBtn m-2" (click)="enableEditing()">Edit </button>
          <button type="submit" mat-button class="profileSaveBtn m-2" [disabled]="!isEditing" (click)="updateProfile(adminProfileUpdate.value)">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
