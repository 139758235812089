<div class="card holidayCard">
    <div class="row m-0 p-0 mainContent">
      <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5 col-xxl-5 text">*Sunday Holiday</div>
      <div class="col-12 col-sm-6 col-md-5 col-lg-7 col-xl-7 col-xxl-7">
     
          <p class="name"></p>
          <mat-form-field appearance="outline" class="example-form-field1">
            <mat-select ngModel name="selectedAdminStatus" [(ngModel)]="selecteYear" placeholder="Filter by year"
                        (selectionChange)="onSelectionChangeYear($event)">
              <mat-option *ngFor="let year of calenderYears" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        <button *ngIf="role != 'admin'" class="upload" (click)="triggerFileUpload()">Upload File</button>
         <input type="file" #fileInput accept=".csv" (change)="onFileSelected($event)" style="display: none;" />
         
      </div>
      <div class="row m-0 p-0 cardss">
        <div *ngFor="let month of getMonthNames()" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 main">
          <div class="months">
            <button>{{ month }}</button>
          </div>
          <div class="card pt-5 eachCard">
            <div *ngFor="let holiday of holidaysByMonth[month]" class="holiday-item">
              <div class="holiday-date">{{ holiday.date }}</div>
              <div class="holiday-description">{{ holiday.description }}</div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  