// import { CanActivateFn } from '@angular/router';

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service'; // Ensure AuthService is implemented

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // constructor(private authService: AuthService, private router: Router) {}

  // canActivate(): boolean {
  //   if (this.authService.isAuthenticated()) {
  //     this.router.navigate(['/home']);
  //     return true;
  //   } else {
  //     this.router.navigate(['/signin']); // Redirect to login if not authenticated
  //     return false;
  //   }
  // }


  constructor(private AuthGuard: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (!this.AuthGuard.isAuthenticated()) {
    //   this.router.navigate(['/signin']);
    //   return false;
    // }

    // return true;

    const token = localStorage.getItem('accessToken');
    const role = localStorage.getItem('Role');

    if (!token) {
      // No token, redirect to sign-in
      this.router.navigate(['/signin']);
      return false;
    }

    // if (role === 'Admin' && state.url.startsWith('/admin')) {
    //   // Admin user accessing admin paths
    //   return true;
    // } else if (role === 'User' && !state.url.startsWith('/admin')) {
    //   // Regular user accessing non-admin paths
    //   return true;
    // } else {
    //   // Role mismatch, redirect to the appropriate page
    //   if (role === 'admin') {
    //     this.router.navigate(['/admin/dashboard']);
    //   } else {
    //     this.router.navigate(['/admin/signin']);
    //   }
    //   return false;
    // }


    if ((role === 'admin' || role === 'super-admin') && state.url.startsWith('/admin')) {
      // Admin or SuperAdmin user accessing admin paths
      return true;
    } else if (role === 'User' && !state.url.startsWith('/admin')) {
      // Regular user accessing non-admin paths
      return true;
    } else {
      // Role mismatch, redirect to the appropriate page
      if (role === 'admin' || role === 'super-admin') {
        this.router.navigate(['/admin/dashboard']);
      } else {
        this.router.navigate(['/signin']);
      }
      return false;
    }
    

  }
  }

