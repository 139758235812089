<app-spinner></app-spinner>

<div class="signup-cont">
  <div class="center-container">

    <mat-card *ngIf="showDiv1" class="signupcard mt-5">
      <div class="row m-0 p-0">
        <div class="col-md-6 col-xl-6 col-xxl-6 p-0 bacimg">
          <img src="../../assets/images/LoginIcon.png" alt="Background Image" />
        </div>
        <div class="col-md-6 col-xl-6 col-xxl-6 cardimg ">
          <form #loginForm="ngForm">

            <div class="" style="width: 100%; margin: 10px;">
              <label class="labels">Email<b style="color: red;"> *</b></label>
              <input class="example-form-field" ngModel name="email" matInput type="text" placeholder="Enter email"
                required >
            </div>
            <div style="width: 100%; margin: 10px; position: relative">
              <div style="position: relative;">
                <label class="labels">Password<b style="color: red;"> *</b></label>
                <input class="field" [type]="passwordFieldType" ngModel name="password" placeholder="Enter password"
                  required autocomplete="off" />
                <img class="eye" src="../../assets/images/icons-eye.png" (click)="togglePasswordVisibility()">
              </div>
            </div>
            <p class="fgt" (click)="toggleDivs(loginForm.value)">Forgot Password?</p>
            <div class="regbtn">
              <button [disabled]="loginForm.invalid" (click)="login(loginForm.value)" class='reg'
                mat-raised-button>User Login</button>
              <p class="haveact mt-1">Don’t have an Account? <span style="cursor: pointer;" routerLink="/signup"
                  class="underline-text">Register </span></p>
                  <a style="cursor: pointer;" class="adminlink mt-1" routerLink="/admin/signin">Go to Admin Signin</a>
                   <!-- <a routerLink="/admin/signin" class="btn btn-primary"></a> -->
            </div>
          </form>
        </div>
      </div>
    </mat-card>

    <mat-card *ngIf="!showDiv1" class="signupcard1 mt-5">
      <div class="container">
        <div class="row m-0 p-0" style="justify-content: center;">
          <div class="passwordTitle mt-2" align="center">
            <h1 class="password">Set New Password</h1>
          </div>

          <div style="width: 70%; margin: 10px;">
            <input class="example-form-field" ngModel [(ngModel)]="this.userEmail" name="email" matInput type="text"
              placeholder="Enter email" required autocomplete="off">
          </div>

          <div style="width: 70%; margin: 10px;" align="center">
            <button class='reg' mat-button (click)="log()">Reset Password</button>
          </div>
        </div>
      </div>
    </mat-card>

  </div>
</div>