<app-spinner></app-spinner>
<div class="back p-1 d-flex">
  <button class="backbtn" routerLink="/home">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div>
    <h4 class="backtext" routerLink="/home">Back</h4>
  </div>
</div>

<div class="whole">
  <div class="d-flex mb-2">
    <img class="note" src="../../assets/images/icons-cartMain.png">
    <p class="pm">Cart Management</p>
  </div>

  <div class="row">

    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <div class="table_top" *ngFor="let item of getCartDataData; let i = index;">
        <div class="d-flex">
          <p class="quot mt-1">PCB Name: <span class="name">{{ item.pcbName }}</span></p>
          <!-- <input (click)="calculation(item.slc_price.id)" type="checkbox" class="editbox mt-1"> -->
          <input 
          (change)="calculation($event, item.slc_price.id)" 
          type="checkbox" 
          class="editbox mt-1"
        />
        
        
          <!-- <img class="edit mt-1" src="../../assets/images/cart-editIcon.png"> -->
        </div>
        <div class="table_bcg">
          <table class="quote_table">
            <thead>
              <tr>
                <th style="width: 20%;">Quantity</th>
                <th style="width: 20%;">Working Days</th>
                <th style="width: 20%;">PCB Name</th>
                <th style="width: 20%;">Est. Delivery Date</th>
                <th style="width: 20%;">Value of Order</th>
              </tr>
            </thead>
            <tbody>
              <tr class="mt-2">
                <td>{{ item.slc_price.quantity }}</td>
                <td>{{ item.slc_price.leadTime }}</td>
                <td>{{ item.pcbName }}</td>
                <td>{{ item.delivery_date | date: "mediumDate" }}</td>
                <td>{{ item.slc_price.grandTotal | currency:'INR' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
      <div class="table_top" *ngIf="getCartDataData.length > 0">
        <div class="d-flex">
          <p class="name mt-1">Order Total</p>
        </div>
        <div class="table_bcgm">
          <div class="row">
            <p class="quot_value col-5">Freight charges</p>
            <p class="dash col-2">-</p>
            <p class="name_value col-5">Free</p>
          </div>
          <div class="row">
            <p class="red_value col-5">Total Amount</p>
            <p class="dash col-2">-</p>
            <!-- <p class="name_value col-5">{{ orderDetails?.grandTotal ?? 0 | currency:'INR'}}</p> -->
            <p class="name_value col-5">{{ getGrandTotal() | currency:'INR' }}</p>
          </div>
          <div class="CHECK row" style="justify-content: center;">
            <button mat-button [disabled]="orderDetails?.grandTotal <= 0" (click)="cartPayment(orderDetails)"
              class="checkout">CheckOut</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>


<ng-template #OrderValueDialog class="bgdone-dialog">
  <div class="create-post-success">
      <div class="publishdialog ">
          <div class="d-flex">
              <h5>Cart management</h5>
          </div>

      </div>
      <div class="row m-0 p-0">
          <div class="message col-12">
              <h3>Maximum Limit reached: Only 5 items allowed.</h3>
              <!-- <h4>Please increase quantity, size, layers...</h4> -->
              <div class="d-flex mt-5 mb-2 justify-content-center">
                  <!-- <button matDialogClose class="quickmessage">
                      CANCEL
                  </button> -->
                  <button matDialogClose class="myposts">
                      OKAY
                  </button>
              </div>
          </div>
      </div>
  </div>
</ng-template>