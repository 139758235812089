<app-spinner></app-spinner>

<div style="padding-top: 100px;" class="row m-0">
    <div class="col-4">
        <div class="back p-3 d-flex">
            <button class="backbtn" routerLink="/home">
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <div>
                <h4 class="backtext" routerLink="/home">Back</h4>
            </div>
        </div>
    </div>

    <div class="col-4" align="right">
        <p class="name mt-4"></p>
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" (dateChange)="onStartDateChange($event)">
            <input matEndDate placeholder="End date" (dateChange)="onEndDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      
    <div class="col-4" align="right">
        <p class="name mt-4"></p>
        <mat-form-field appearance="outline" class="example-form-field">
            <mat-select ngModel name="selectedDocuments" [(ngModel)]="selectedDocuments" placeholder="Filter by status"
                (selectionChange)="onSelectionChange($event)">
                <mat-option *ngFor="let doc of paymentStatus" [value]="doc.value">
                    {{ doc.payment_status }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
  
<div class="whole">
    <!-- <div class="d-flex mt-1 mb-2">
        <img style="width: 25px; height: 25px;" src="../../assets/images/icons-quotation.png">
        <p class="pm mb-1">Quote Management</p>
    </div> -->

    <div class="row mt-1 mb-2">
        <div class="col-6 d-flex align-items-center">
            <div class="d-flex mt-1 mb-2">
                <img style="width: 25px; height: 25px;" src="../../assets/images/icons-quotation.png">
                <p class="pm mb-1">Order management </p>
            </div>
        </div>

    </div>
    
   
    <div class="table_top" *ngFor="let item of QuoteManagementData; let i = index;">
        <div class="d-flex">
            <p class="quot mt-1">PCB Name:<span class="name">{{ item.pcbName }}</span></p>
        </div>
        <div class="table_bcg">
            <table class="quote_table">
                <tr>
                 
                    <th style="width: 8%;">Quote No</th>
                    <th style="width: 10%;">PCB Name</th>
                    <th style="width: 10%;">Order Date</th>
                    <th style="width: 10%;">Delivery Date</th>
                    <th style="width: 8%;">Order No</th>
                    <th style="width: 10%;">Payment Date</th>
                    <th *ngIf="item.payment_status === 'Success'" style="width: 10%;">Transaction Id</th>
                    <th *ngIf="item.payment_status === 'Success'" style="width: 8%;">Courier Id</th>
                    <th style="width: 10%;">Courier Link</th>
                    <th *ngIf="item.payment_status !== 'Success'" style="width: 8%;">Payment Status</th>
                    <th style="width: 8%;">Status</th>
                </tr>
                <tr class="mt-2">
                  
                    <td>{{item.id}}</td>
                    <td>{{item.pcbName}}</td>
                    <td>{{item.order_date | date: "mediumDate"}}</td>
                    <td>{{item.delivery_date | date: "mediumDate"}}</td>
                    <td>{{item.payment_details?.order_id || '-' }}</td>
                       <td> <ng-container *ngIf="item.payment_status === 'Success'; else hyphen">
                            {{item.payment_details?.paymentDateTime | date: "mediumDate"}}
                        </ng-container>
                        <ng-template #hyphen>-</ng-template>
                    </td>
    
                    <td> <ng-container *ngIf="item.payment_status === 'Success'; else hyphen2">
                            {{item.payment_details?.transactionId}}
                        </ng-container>
                        <ng-template #hyphen2>-</ng-template>
                    </td>
                 
                    <td *ngIf="item.payment_status === 'Success'">{{item.track_id || '-' }}</td>
                    <!-- <td matTooltip="{{item.track_url}}" *ngIf="item.payment_status === 'Success'">{{item.track_url | slice:0:10}}</td> -->

                    <td *ngIf="item.payment_status === 'Success'">
                        <a *ngIf="item.track_url" [href]="item.track_url" target="_blank">
                          {{ item.track_url | slice:0:20 }}...
                        </a>
                        <span *ngIf="!item.track_url">-</span>
                      </td>
                      
                      
                      <td style="color: red;" *ngIf="item.payment_status !== 'Success'">{{paymentStatusQuoteManagement(item.payment_status)}}</td>

                      <td *ngIf="item.payment_status === 'Success'"
                      [ngClass]="{'success-status': item.payment_status === 'Success', 'error-status': item.payment_status !== 'success'}">
                      {{formatStatusQuoteManagement(item.order_status)}}</td>
                    <td *ngIf="item.payment_status !== 'Success'"> 
                        <button *ngIf="item.payment_status !== 'Success'" mat-button class="payBtn" routerLink=""
                            (click)="QuotePayment(item.quote_id, item?.slc_price?.grandTotal)" [disabled]="item.payment_status === 'Expired'">Pay</button>
                        <button *ngIf="item.payment_status === 'Success'" mat-button class="paidBtn"
                            [disabled]="item.payment_status === 'Success'">paid</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="pb-4">
        <mat-paginator
          #paginator
          [length]="pagination"
          class="page1"
          [pageSizeOptions]="[5]" 
          [pageSize]="5" 
          [showFirstLastButtons]="true"
          (page)="allOrdersPagination($event)"
        >
        </mat-paginator> 
      </div>
</div>


<ng-template #OrderValueDialog class="bgdone-dialog">
    <div class="create-post-success">
        <div class="publishdialog ">
            <div class="d-flex">
                <h5>Order management</h5>
            </div>

        </div>
        <div class="row m-0 p-0">
            <div class="message col-12">
                <h3>No data found.</h3>
                <!-- <h4>Please increase quantity, size, layers...</h4> -->
                <div class="d-flex mt-5 mb-2 justify-content-center">
                    <!-- <button matDialogClose class="quickmessage">
                        CANCEL
                    </button> -->
                    <button matDialogClose class="myposts">
                        OKAY
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>