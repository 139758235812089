<app-spinner></app-spinner>
<div class="card priceCard">
    <!-- <h2>Price Update</h2> -->
    <!-- <div class="fixedHeader row">
        <div class="col-3">
        </div>
        <div class="col-7">
            <div class="row">
              <div class="col-3">
                <h1 style="font-size: large;">1 Layer</h1>
              </div>
              <div class="col-3">
                <h1  style="font-size: large;">2 Layer</h1>
              </div>
              <div class="col-3">
                <h1  style="font-size: large;">4 Layer</h1>
              </div>
              <div class="col-3">
                <h1  style="font-size: large;">6 Layer</h1>
              </div>
            </div>
          </div>
          
     
    </div> -->

    <div class="fixedHeader row">
        <!-- <div class="col-2 m-0 p-0" >
            <p>Base Layer Price</p>
        </div> -->
        <!-- <div class="col-8 m-0 p-0 ml-2">
            <div class="row m-0 p-0">
                <div class="col-3 m-0 p-0">
                    <button class="btnnn">1 Layer</button>
                </div>
                <div class="col-3 m-0 p-0">
                    <button class="btnnn">2 Layer</button>
                </div>
                <div class="col-3 m-0 p-0">
                    <button class="btnnn">4 Layer</button>
                </div>
                <div class="col-3 m-0 p-0">
                    <button class="btnnn">6 Layer</button>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-8 m-0 p-0">
            <div class="col d-flex justify-content-between align-items-center">
                <button class="btnnn">1 Layer</button>
                <button class="btnnn">2 Layer</button>
                <button class="btnnn">4 Layer</button>
                <button class="btnnn">6 Layer</button>
            </div>
        </div> -->

        <div class="pricesection">
            <div class="section">
                <div class="row m-0 p-0 pricemain">
                    <div class="col-12 col-md-2 text-center text-md-start">
                        <!-- <h3 class="pricetext">Base Layer Price</h3> -->
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="row">
                            <div *ngFor="let layer of boardLayers" class="pricecontent col-6 col-md text-center mt-2">
                                <button class="btn btn-outline-secondary w-100">{{ layer }} Layer</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>


    <div class="pricesection">

        <div class="section">
            <div class="row m-0 p-0 pricemain mt-2">
                <div class="col-12 col-md-2 text-center text-md-start">
                    <h3 class="pricetext">Base Layer Price</h3>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div *ngFor="let layer of boardLayers" class="pricecontent col-6 col-md text-center mt-2">
                            <button class="btn btn-outline-secondary w-100">{{ layer }} Layer</button>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div *ngFor="let layer of boardLayers" class="col-6 col-md text-center mt-2">
                            <input [(ngModel)]="boardLayerData[layer]" [disabled]="borderLayerInputDisabled"
                                type="number" class="priceInput w-100" [placeholder]="layer + ' Layer Price'"
                                (ngModelChange)="onBoardLayerPriceChange(layer, $event)">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="borderLayer()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateBorderLayerPricing()">Save</button>
                </div>
            </div>

        </div>

        <div class="section">
            <div class="row mt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                    <h3 class="pricetext">Thickness</h3>
                </div>
            </div>

            <div *ngFor="let thickness of thicknesses" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 text-center pricecontent mt-2">
                    <button class="w-100">{{ thickness }}</button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 text-center inputt mt-2">
                    <input [(ngModel)]="priceData[thickness][layer]" [disabled]="thicknessInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput w-100">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="enableInputs()">Edit</button>
                    <button mat-button class="mx-2" (click)="updatePricing()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row mt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                    <h3 class="pricetext">Finished Copper</h3>
                </div>
            </div>

            <div *ngFor="let finishedCopper of finishedCoopers" class="row pricemain m-0 mt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 text-center pricecontent">
                    <button class="w-100">{{ finishedCopper }}</button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 text-center inputt mt-2">
                    <input [(ngModel)]="finishedCopperData[finishedCopper][layer]"
                        [disabled]="finishedCopperInputDisabled" type="number" [placeholder]="layer + ' Layer'"
                        class="priceInput w-100"
                        (ngModelChange)="onFinishedCopperPriceChange(finishedCopper, layer, $event)">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="finishedCopper()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateFinishedCopperPricing()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row mt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                    <h3 class="pricetext">IL Copper</h3>
                </div>
            </div>

            <div *ngFor="let ilCopper of ilCoopers" class="row pricemain m-0 mt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 text-center pricecontent">
                    <button class="w-100">{{ ilCopper }}</button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 text-center inputt mt-2">
                    <input [(ngModel)]="ilCopperpriceData[ilCopper][layer]" [disabled]="ILcooperEdit" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput w-100"
                        (ngModelChange)="onILCopperPriceChange(ilCopper, layer, $event)">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="ILcooper()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateILCopperPricing()">Save</button>
                </div>
            </div>
        </div>

        <div class="sectionn">
            <div class="row pricemain mt-2">
                <div class="col-12 col-md-2">
                    <h3 class="pricetext">Solder Mask</h3>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row" *ngFor="let row of solderMasks | slice:0:4; let i = index">
                        <div class="pricecontent col-12 col-sm-6 col-md-3 text-center"
                            *ngFor="let solderMaskName of solderMasks.slice(i * 4, i * 4 + 4)">
                            <div class="mb-2">
                                <button class="btn btn-outline-secondary w-100">{{ solderMaskName }}</button>
                            </div>
                            <div>
                                <input [(ngModel)]="solderMaskCostData[solderMaskName]" type="number"
                                    class="priceInput w-100" [placeholder]="solderMaskName"
                                    [disabled]="solderMaskInputDisabled"
                                    (ngModelChange)="onSolderMaskCostChange(solderMaskName, $event)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="solderMask_name()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateSolderMaskCosts()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row mt-2">
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <h3 class="pricetext">Surface Finish</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let surfaceFinish of surfaceFinishes" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button class="btn btn-outline-secondary w-100">{{ surfaceFinish }}</button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt text-center mb-2">
                    <input [(ngModel)]="surfacepriceData[surfaceFinish][layer]" [disabled]="surfaceFinishInputDisabled"
                        type="number" [placeholder]="layer + ' Layer'" class="priceInput w-100"
                        (ngModelChange)="onSurfacePriceChange(surfaceFinish, layer, $event)">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="text-end">
                    <button mat-button class="mx-2" (click)="surfaceFinish()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateSurfacePricing()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <h3 class="pricetext">Material</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let material of materials" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent text-center">
                    <button class="btn btn-outline-secondary w-100">{{ material }}</button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt text-center mb-2">
                    <input [(ngModel)]="materialData[material][layer]" [disabled]="materialInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput w-100"
                        (ngModelChange)="onMaterialPriceChange(material, layer, $event)">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="text-end">
                    <button mat-button class="mx-2" (click)="material()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateMaterialPricing()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row pricemain mt-2">
                <div class="col-12 col-md-2">
                    <h3 class="pricetext">Tooling</h3>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row" *ngFor="let row of toolingCosts | slice:0:4; let i = index">
                        <div class="pricecontent col-12 col-sm-6 col-md-3 text-center"
                            *ngFor="let toolingName of toolingCosts.slice(i * 4, i * 4 + 4)">
                            <div class="mb-2">
                                <!-- <button class="btn btn-outline-secondary w-100">{{ toolingName }}</button> -->
                                <button class="btn btn-outline-secondary w-100">
                                    {{ toolingName === 'SS' ? '1 Layer'
                                    : (toolingName === 'DS' ? '2 Layer'
                                    : (toolingName === 'ML4' ? '4 Layer'
                                    : (toolingName === 'ML6' ? '6 Layer'
                                    : toolingName)))
                                    }}
                                </button>

                            </div>
                            <div>
                                <input [(ngModel)]="toolingCostData[toolingName]" type="number" class="priceInput w-100"
                                    [placeholder]="toolingName" [disabled]="tooling_nameInputDisabled"
                                    (ngModelChange)="onToolingCostChange(toolingName, $event)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="col-12 text-end">
                    <button mat-button class="mx-2" (click)="tooling_name()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateToolingCosts()">Save</button>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <h3 class="pricetext">Minimum Cut Off Price</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let orderType of orderTypes" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent text-center">
                    <button class="btn btn-outline-secondary w-100">
                        {{ orderType === 'Standard' ? 'Express' : (orderType === 'Premium' ? 'Super-Fast' : orderType)
                        }}
                    </button>
                </div>

                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt text-center mb-2">
                    <input [(ngModel)]="basepriceData[orderType][layer]" [disabled]="basePriceInputDisabled"
                        type="number" [placeholder]="layer + ' Layer'" class="priceInput w-100"
                        (ngModelChange)="onBasePriceChange(orderType, layer, $event)">
                </div>
            </div>

            <div class="row buttons mt-3">
                <div *ngIf="role !== 'admin'" class="text-end">
                    <button mat-button class="mx-2" (click)="basePriceEdit()">Edit</button>
                    <button mat-button class="mx-2" (click)="updateBasePricing()">Save</button>
                </div>
            </div>
        </div>
    </div>

</div>