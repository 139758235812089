import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  role: string | null = '';
  ProfileData: any;
  name: any;
  userId: any;


  constructor(private router: Router, private server: ApiService,) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.AdminData();
  }

  goToSignupScreen() {
    localStorage.clear();
    this.router.navigate(['/signin']);
  }

  logOut() {
    localStorage.clear();
    window.location.href = '/signin';
  }
  // Assuming this code is within your component class
  userIdExists(): boolean {
    return !!localStorage.getItem('userId');
  }

  isLoggedIn(): boolean {
    const userId = localStorage.getItem('userId');
    return !!userId; // Returns true if userId is truthy (not null or undefined)
  }

  logOutAdmin() {
    localStorage.clear();
    window.location.href = '/admin/signin';
  }

  AdminData() {
    let data = {
      admin_id: localStorage.getItem('userId')
    }
    this.server.getAdminById(data).subscribe((res: any) => {
      console.log(res, 'dataaaaaa');
      this.ProfileData = res.result;
      this.name = this.ProfileData.firstName
      this.userId = this.ProfileData.id
      console.log(this.ProfileData, 'ProfileData')
    })
  }

}
