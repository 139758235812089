import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-quote-management',
  templateUrl: './quote-management.component.html',
  styleUrls: ['./quote-management.component.scss'],
  providers: [DatePipe] 
})
export class QuoteManagementComponent implements OnInit {
  @ViewChild('OrderValueDialog') OrderValueDialog!: TemplateRef<any>;
  selectedStartDate: string | null = null;
  selectedEndDate: string | null = null;
  dateRange = { start: this.selectedStartDate, end: this.selectedEndDate };
  QuoteManagementData: any;
  selectedDocuments: any;
  paginatedData: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 5;
  pagination: any;
  
  constructor(
    private server: ApiService,
    private router: Router,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // this.QuoteData();
    this.Status();
    // this.logSelectedDates();

  }

  ngAfterViewInit(): void {
    // This will open the dialog after the view has been initialized
    if (this.QuoteManagementData?.length === 0) {
      this.openPopup();
    }
  }

  paymentStatus = [
    { id: '1', payment_status: 'Paid', value: 'successful' },
    { id: '2', payment_status: 'Unpaid', value: 'unsuccessful' }
  ];
 

  QuoteData() {
    this.spinner.show();
    let obj = {
      user_id: localStorage.getItem('userId'),
      // startDate: this.selectedStartDate,
      // endDate: this.selectedEndDate
    }
    console.log(obj, 'quote obj')
    this.server.QuoteManagementData(obj).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'res quote');
      this.QuoteManagementData = res.result;
      console.log(this.QuoteManagementData, 'QuoteManagementData');
    })
  }

  goToHomeScreen() {
    this.router.navigate(['/home']);
  }

  // QuotePayment(QuoteId: any) {
  //   console.log(QuoteId, 'quoteId');
  //   this.router.navigate(['/shipping', { quoteId: QuoteId }]);
  // }

  // QuotePayment(QuoteId: any) {
  //   console.log(QuoteId, 'quoteId');

  //   // Navigate to the shipping route with quoteId as query parameter
  //   this.router.navigate(['/shipping'], {
  //     queryParams: {
  //       quoteId: QuoteId,
  //     }
  //   });
  // }

  QuotePayment(id: any, Amount:any) {
    console.log(id, 'ids');
    console.log(Amount, 'Amount');
    const selectedCartIds = id;
    console.log(selectedCartIds, 'selected cart ids');
    // const grandTotal = Amount
    // console.log(grandTotal, 'grand total');
    // Navigate to the shipping route with both quoteId and grandTotal
    this.router.navigate(['/shipping'], {
      queryParams: {
        quoteId: selectedCartIds,
        // grandTotal: grandTotal
      }
    });
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectedDocuments = event.value;
    console.log('Selection changed:', this.selectedDocuments);
    // Add any additional logic you want to execute when selection changes
    this.Status();
  }

  Status() {
    this.spinner.show();
    let obj = {
      user_id: localStorage.getItem('userId'),
      page: this.pageNumber,
      size: this.pageSize,
      payment_status: this.selectedDocuments || 'successful',
      startDate: this.selectedStartDate || '',
      endDate: this.selectedEndDate || ''
    }
    console.log(obj, 'quote obj')
    this.server.getQuotesByPaymentStatus(obj).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'res getQuotesByPaymentStatus');
      this.QuoteManagementData = res.data;
      console.log(this.QuoteManagementData, 'QuoteManagementData');
      this.pagination = res.pagination.totalQuotes;
      console.log(this.pagination, 'pagination')
      if (this.QuoteManagementData.length === 0) {
        this.openPopup();
      }
    })
  }

 
  allOrdersPagination(event: { pageIndex: number; pageSize: number; }) {
    this.pageNumber = event.pageIndex + 1; // Angular paginator is 0-indexed
    this.pageSize = event.pageSize;
    console.log(this.pageNumber, 'pageNumber');
    console.log(this.pageSize, 'pageSize');
    this.Status(); // Fetch new data based on page changes
  }

rrr(){
 const dialogRef = this.dialog.open(this.OrderValueDialog);
      dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog closed with result:', result);
      });
}

openPopup(): void {
  const dialogRef = this.dialog.open(this.OrderValueDialog);
  dialogRef.afterClosed().subscribe(result => {
    console.log('Dialog closed with result:', result);
  });
}
   

   // Helper function to format the date
   formatDate(date: Date | null): string | null {
    if (!date) return null;
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    return formattedDate;
  }

  // Method to handle start date change
  onStartDateChange(event: any) {
    this.selectedStartDate = this.formatDate(event.value);
    console.log('Formatted Start Date:', this.selectedStartDate);
    
    // Call thisQuoteData() if both dates are selected
    if (this.selectedStartDate && this.selectedEndDate) {
      this.Status();
    }
  }

  // Method to handle end date change
  onEndDateChange(event: any) {
    this.selectedEndDate = this.formatDate(event.value);
    console.log('Formatted End Date:', this.selectedEndDate);
    
    // Call thisQuoteData() if both dates are selected
    if (this.selectedStartDate && this.selectedEndDate) {
      this.Status();
    }
  }

  // formatStatus(status: string): string {
  //   return status?.replace(/-/g, ' ');
  // }

  paymentStatusQuoteManagement(orderStatus: string): string {
    let formattedStatus = orderStatus?.replace(/-/g, ' ');
    if (orderStatus === 'Aborted') {
      return 'Unpaid';
    } else  if (orderStatus === 'not-started') {
      return 'Unpaid';
    }
      // Capitalize the first letter of each word
      return formattedStatus.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  formatStatusQuoteManagement(orderStatus: string): string {
    let formattedStatus = orderStatus?.replace(/-/g, ' ');
    if (orderStatus === 'order-received') {
      return 'In Progress';
    } else if (orderStatus === 'order-delivered') {
      return 'Delivered';
    } else if (orderStatus === 'order-shipped') {
      return 'Shipped';
    }
      // Capitalize the first letter of each word
      return formattedStatus.replace(/\b\w/g, (char) => char.toUpperCase());
  }


}
