import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-holiday-calendar',
  templateUrl: './holiday-calendar.component.html',
  styleUrls: ['./holiday-calendar.component.scss']
})
export class HolidayCalendarComponent implements OnInit {

  @ViewChild('fileInput') fileInput: any;
  selectedFile: File | null = null;
  holidaysByMonth: any = {};
  fileName: string = '';
  date = new FormControl();
  selecteYear: any;
  calenderYears: any;
  role: any;
  constructor(private server: ApiService, private snackBar: MatSnackBar) { 
  
  }
  // ngOnInit() {
  //   this.HolidayCalender();
  // }



  // HolidayCalender() {
  //   const year = 2024
  //   this.server.getHolidayCalender(year).subscribe((res: any) => {
  //   console.log(res, 'ressssssss')
  //   })
  // }



  // constructor(private server: YourService) {}

  ngOnInit(): void {
    this.HolidayCalender();
    this.CalenderYears();
    const storedYear = localStorage.getItem('selectedYear');
    const currentYear = new Date().getFullYear();
    this.selecteYear = storedYear ? +storedYear : currentYear;
    this.role = localStorage.getItem('Role');
    console.log(this.role, 'roleeee');
  }


  calendarByYear = [
    { id: '1', year: '2024', value: '2024' },
    { id: '2', year: '2025', value: '2025' },
  ]; 

  onSelectionChangeYear(event: MatSelectChange) {
     this.fileName = ''
    this.selecteYear = event.value;
    localStorage.setItem('selectedYear', this.selecteYear.toString()); // Store the selected year
    console.log('Selected Year:', this.selecteYear);
    console.log('Selection changed:', this.selecteYear);
    this.HolidayCalender();
  }

  HolidayCalender() {
     this.fileName = ''
    const year = this.selecteYear || '2024'
    this.server.getHolidayCalender(year).subscribe((res: any) => {
      this.holidaysByMonth = this.organizeHolidaysByMonth(res.result);
    });
  }

  organizeHolidaysByMonth(holidays: any[]): any {
    const months: any = {};
    holidays.forEach(holiday => {
      const date = new Date(holiday.date);
      const month = date.toLocaleString('default', { month: 'long' });

      if (!months[month]) {
        months[month] = [];
      }

      months[month].push({
        date: date.getDate(),
        day: holiday.day.trim(),
        description: holiday.Holiday_description
      });
    });
    return months;
  }


  getMonthNames(): string[] {
    return [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  
  // Handle file selection
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.fileName = file.name; 
      console.log('Selected file:', file);
      this.data();
    }
  }
  
  // Data upload function
  data() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
  
      // Call the API to upload the file
      this.server.uploadCalenderCsv(formData).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.fileName = '';
            this.snackBar.open('File uploaded successfully', 'x', {
              duration: 3000,
              panelClass: ['custom-style'],
              verticalPosition: 'top' // Position snackbar at the top
            });
          } else if (res.statusCode === 400) {
            this.snackBar.open('File upload failed. Please check the file format and try again.', 'x', {
              duration: 3000,
              panelClass: ['custom-style'],
              verticalPosition: 'top' // Position snackbar at the top
            });
          }
          console.log('Response:', res);
  
          // Reset selected file and clear file input after successful upload
          this.selectedFile = null;
          this.fileInput.nativeElement.value = ''; // Clear file input
        },
        (err: any) => {
          console.error('Error:', err);
          this.snackBar.open('An error occurred during file upload.', 'x', {
            duration: 3000,
            panelClass: ['custom-style'],
            verticalPosition: 'top' // Position snackbar at the top
          });
        }
      );
    } else {
      console.error('No file selected!');
    }
  }
  
  


  // // Method to set the selected year
  // setYear(year: number, datepicker: MatDatepicker<Date>) {
  //   const selectedDate = new Date(year, 0); // Set to January 1st of the selected year
  //   this.date.setValue(selectedDate); // Set the FormControl value to the selected year
  //   datepicker.close(); // Close the datepicker after selection
  // }

  CalenderYears() {
    this.server.getCalenderYears().subscribe((res: any) => {
      console.log(res, 'count');
      this.calenderYears = res.result;
      console.log(this.calenderYears, 'calenderYears');
    })
  }

  
}


