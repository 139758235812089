import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {}

  // Dummy method to check if the user is authenticated
  isAuthenticated(): boolean {
    // Logic to check if user is logged in (could check for token in localStorage, sessionStorage, etc.)
    return !!localStorage.getItem('accessToken');
  }
}
