import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit{
  @ViewChild('adminProfileUpdate') adminProfileUpdate!: NgForm;
  name: string = 'Name';
  mobilenumber: string = '1234567890';
  email: string = 'example@example.com';
  showPassword: boolean = false;
  company_Name: any;
  adminId: any;
  currentEditable: string = ''; // Track the currently editable field
url="../../assets/images/profile-Pcl.svg";
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  nameFormControl = new FormControl('', [Validators.required]);
  mobileNumberFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  AmdinProfileData: any;
  togglePasswordVisibility(passwordField: HTMLInputElement): void {
    this.showPassword = !this.showPassword;
    console.log(' this.showPassword', this.showPassword);
    
    passwordField.type = this.showPassword ? 'text' : 'password';
  }

  constructor(private server: ApiService, private snackBar: MatSnackBar) {
  }
  ngOnInit() {
    this.AdminById();
  }


  onSelect(e:any){
    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload=(event:any)=>{
        this.url=event.target.result;
        console.log('Selected File',this.url);
        
      }
    }
  }
  onEditClick(field: string): void {
    this.currentEditable = field; 
    console.log('currentEditable',this.currentEditable);
    
  }
  onSaveClick(field: string): void {
    this.currentEditable = '';
    // Type assertion to access the property dynamically
    const fieldValue = (this as any)[field];
    console.log(`Saved ${field}:`, fieldValue);
  }

  getBackgroundStyle(field: string): object {
    return {
      'background-image': this.currentEditable === field
        ? 'url(../../assets/images/save.png)'
        : 'url(../../assets/images/edit-pcl.svg)'
  
    };
  }
  openFilePicker() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }


  AdminById() {
    let data = {
      admin_id: localStorage.getItem('userId')
    }
    this.server.getAdminById(data).subscribe((res: any) => {
        console.log(res, 'dataaaaaa');
        this.AmdinProfileData = res.result;
        this.name = this.AmdinProfileData.firstName
        this.email = this.AmdinProfileData.email
        this.mobilenumber = this.AmdinProfileData.mobile
        this.company_Name = this.AmdinProfileData.company
        this.adminId = this.AmdinProfileData.id
        console.log( this.company_Name, 'this.AmdinProfileData')
      })
  }

  isEditing = false;

  updateProfile(form: any) {
    // console.log(form, 'form admin');
    let obj = {
      admin_id: this.adminId,
      firstName: form.name,
      lastName: form.name,   // Assuming both are the same
      email: form.email,
      mobile: form.mobilenumber,
      company: form.company_Name
    };
    // console.log(obj, 'obj');
    this.server.updateAdminProfile(obj).subscribe((res: any) => {
      // console.log(res, 'response');
      if (res.statusCode === 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // Disable editing after successful update
        this.isEditing = false;
      } else if (res.statusCode === 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
  }
  
  enableEditing() {
    this.isEditing = true;
  }
  
  
  

 

 

}
